import "./skills.css";
import TitleSection from "../../Composant/TitleSection/titleSection";
import MediaRessources from "../../ressources/media-ressources";

const logos_back = {
  titre: "Back-End",
  techno: [
    {
      id: 1,
      alt: "Logo du language de programmation : Java",
      title: "Java",
      src: MediaRessources.java,
    },
    {
      id: 2,
      alt: "Logo du framework Java : Spring, SpringBoot",
      title: "SpringBoot",
      src: MediaRessources.springBoot,
    },
    {
      id: 3,
      alt: "Logo du language de programmation : Python",
      title: "Python",
      src: MediaRessources.python,
    },
    {
      id: 4,
      alt: "Logo du language de programmation : PHP",
      title: "PHP",
      src: MediaRessources.php,
    },
    {
      id: 5,
      alt: "Logo du framework PHP : Symfony",
      title: "Symfony",
      src: MediaRessources.symfony,
    },
    {
      id: 6,
      alt: "Logo du framework PHP : Laravel",
      title: "Laravel",
      src: MediaRessources.laravel,
    },
  ],
};

const logos_front = {
  titre: "Front-End",
  techno: [
    {
      id: 7,
      alt: "Logo du language de programmation : Javascript",
      title: "Javascript",
      src: MediaRessources.javascript,
    },
    {
      id: 8,
      alt: "Logo du framework Javascript : React",
      title: "React",
      src: MediaRessources.react,
    },
    {
      id: 9,
      alt: "Logo du framework Javascript : Angular",
      title: "Angular",
      src: MediaRessources.angular,
    },
    {
      id: 10,
      alt: "Logo du CMS : Wordpress",
      title: "Wordpress",
      src: MediaRessources.wordpress,
    },
  ],
};

const logos_bdd = {
  titre: "Bases de Données",
  techno: [
    {
      id: 11,
      alt: "Logo du systeme de Bases de données : MySQL",
      title: "MySQL",
      src: MediaRessources.mySql,
    },

    {
      id: 12,
      alt: "Logo du systeme de Bases de données : PostGreSQL",
      title: "PostGreSQL",
      src: MediaRessources.postgre,
    },

    {
      id: 13,
      alt: "Logo du systeme de Bases de données : MariaDB",
      title: "MariaDB",
      src: MediaRessources.mariaDb,
    },
  ],
};

const logos = [logos_back, logos_front, logos_bdd];

function SkillSection() {
  return (
    <section className="section_skill" id="section-skill">
      <TitleSection title="Compétences" progress="50"></TitleSection>
      <div className="box-logos">
        {logos.map((domaine) => (
          <>
            <div className="titre-techno" key={domaine.titre}>
              {domaine.titre} :
            </div>
            <div className="box-logo">
              {domaine.techno.map((logo) => (
                <div className="logo-skill">
                  <img
                    className="img-logo-skill"
                    key={logo.id}
                    src={logo.src}
                    alt={logo.alt}
                    title={logo.title}
                  ></img>
                  <p className="title-skill">{logo.title}</p>
                </div>
              ))}
            </div>
          </>
        ))}
      </div>
    </section>
  );
}

export default SkillSection;

function controlSelfAndConjoint(participant, secretNomPrenom) {
  // True si ce n'est pas soit meme ou son conjoint
  // False si c'est l'un ou l'autre
  return !(
    participant.nomPrenom == secretNomPrenom ||
    participant.conjoint == secretNomPrenom
  );
}

function shuffleArray(array) {
  // L'algorithme de Fisher-Yates est utilisé pour mélanger aléatoirement un tableau.
  // On parcourt le tableau en sens inverse et, à chaque itération, on échange l'élément actuel avec un élément choisi au hasard parmi les éléments déjà parcourus.
  for (let currentIndex = array.length - 1; currentIndex > 0; currentIndex--) {
    // Génère un index aléatoire entre 0 et l'index actuel inclus.
    const randomIndex = Math.floor(Math.random() * (currentIndex + 1));
    // Échange les valeurs entre l'élément actuel et l'élément choisi aléatoirement.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
}

export function drawNames(listOfParticipants) {
  let shuffledList = [...listOfParticipants];
  shuffleArray(shuffledList);

  const drawnNames = [];
  const maxAttempts = 1000; // Limite de tentatives pour trouver une correspondance valide
  let attempts = 0;

  for (let i = 0; i < listOfParticipants.length; i++) {
    if (attempts >= maxAttempts) {
      throw new Error("Impossible de tirer les noms avec les participants actuels : vérifiez les contraintes.");
    }

    const participant = listOfParticipants[i];
    let validSecrets = shuffledList.filter((secret) =>
      controlSelfAndConjoint(participant, secret.nomPrenom)
    );

    if (validSecrets.length === 0) {
      // Pas de secret valide trouvé, réinitialisez le tirage
      i = -1; // Redémarre la boucle avec l'index 0
      shuffledList = [...listOfParticipants]; // Remélange la liste
      shuffleArray(shuffledList);
      drawnNames.length = 0; // Efface les résultats actuels
      attempts++;
      continue;
    }

    const secret =
      validSecrets[Math.floor(Math.random() * validSecrets.length)];
    drawnNames.push({
      giver: participant.nomPrenom,
      receiver: secret.nomPrenom,
    });
    // Retire le secret de la liste des candidats
    shuffledList.splice(shuffledList.indexOf(secret), 1);
  }
  return drawnNames;
}

import "./caroussel.css"
import { useState } from "react";

function Carousel() {
    const [activeSlide, setActiveSlide] = useState(0);
  
    return (
      <div className="carousel">
        <div className="slide" key="1">
          <img src="https://placeholdit.imgix.net/~text?txtsize=33&txt=Slide%201&w=300&h=150" />
        </div>
        <div className="slide" key="2">
          <img src="https://placeholdit.imgix.net/~text?txtsize=33&txt=Slide%202&w=300&h=150" />
        </div>
        <div className="slide" key="3">
          <img src="https://placeholdit.imgix.net/~text?txtsize=33&txt=Slide%203&w=300&h=150" />
        </div>
        <div className="dots">
          <button onClick={() => setActiveSlide(0)}>
            <span className="dot active"></span>
          </button>
          <button onClick={() => setActiveSlide(1)}>
            <span className="dot"></span>
          </button>
          <button onClick={() => setActiveSlide(2)}>
            <span className="dot"></span>
          </button>
        </div>
      </div>
    );
  }

  export default Carousel;